<template>
  <form>
    <MDBRow class="mb-3">
      <MDBCol md="8">
        <div class="mb-3">
          <label for="status" class="form-label text-dark">{{ Form.title }}</label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="status"
              name="status"
              :checked="Form.status"
              @change="changeStatus"
            />
            <label class="form-check-label" for="status">{{
              $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE")
            }}</label>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  </form>
</template>

<script>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { useI18n } from "vue-i18n";

export default {
  name: "PaymentBranchForm",
  components: {
    MDBRow,
    MDBCol,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const branchesUrl = `Admin/v1/payment/branch`;
    const i18n = useI18n();

    const Form = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });

    const isSubBranch = "parent_uuid" in Form.value;
    const uuid = isSubBranch ? Form.value.parent_uuid : Form.value.uuid;

    const changeStatus = () => {
      const choise = event.target.checked;
      let sendData = {};

      if (isSubBranch) {
        sendData = {
          sub_uuid: Form.value.uuid,
          sub_status: +choise,
        };
      } else {
        sendData = {
          status: +choise,
        };
      }

      return ApiService.update(branchesUrl, uuid, sendData).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
        }
      });
    };

    return {
      Form,
      changeStatus,
    };
  },
};
</script>
