<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer class="mt-3" bg="white">
    <MDBRow>
      <MDBCol md="4" class="mb-2">
        <MDBCard>
          <MDBCardBody>
            <transition name="fade">
              <PageLoading v-if="PageLoading"></PageLoading>
            </transition>
            <MDBCardTitle>{{ $t("COMMON.ECPAYGOLDSETTINGSFORFRONT") }}</MDBCardTitle>
            <PaymentForm :data="FactoriesForm[EcpayFront]" v-model="EcpayFront"></PaymentForm>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" class="mb-2">
        <MDBCard>
          <MDBCardBody>
            <transition name="fade">
              <PageLoading v-if="PageLoading"></PageLoading>
            </transition>
            <MDBCardTitle>{{ $t("COMMON.ECPAYGOLDSETTINGSFORBACK") }}</MDBCardTitle>
            <PaymentForm :data="FactoriesForm[EcpayBackend]" v-model="EcpayBackend"></PaymentForm>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" class="mb-2">
        <MDBCard>
          <MDBCardBody>
            <transition name="fade">
              <PageLoading v-if="PageLoading"></PageLoading>
            </transition>
            <MDBCardTitle>{{ $t("COMMON.DOMESTICACCOUNT") }}</MDBCardTitle>
            <CardForm :data="FactoriesForm[DomestiAccount]" v-model="DomestiAccount"></CardForm>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" class="mb-2">
        <MDBCard>
          <MDBCardBody>
            <transition name="fade">
              <PageLoading v-if="PageLoading"></PageLoading>
            </transition>
            <MDBCardTitle>{{ $t("COMMON.FOREXACCOUNT") }}</MDBCardTitle>
            <CardForm :data="FactoriesForm[ForexAccount]" v-model="ForexAccount"></CardForm>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="4" class="mb-2">
        <MDBCard>
          <MDBCardBody>
            <transition name="fade">
              <PageLoading v-if="PageLoading"></PageLoading>
            </transition>
            <MDBCardTitle>{{ $t("COMMON.ECPAYPAYMENTBRANCH") }}</MDBCardTitle>
            <template v-for="(item, index) in BranchesForm" :key="index">
              <MDBCol md="12">
                <PaymentBranchForm :data="item"></PaymentBranchForm>
                <MDBCol v-if="Object.keys(item.staging).length > 0">
                  <template v-for="(value, key) in item.staging" :key="key">
                    <PaymentBranchForm :data="value"></PaymentBranchForm>
                  </template>
                </MDBCol>
              </MDBCol>
            </template>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import { ref, reactive } from "vue";
import PageLoading from "@/components/Elements/PageLoading";
import ApiService from "@/core/services/api.service";
import { useI18n } from "vue-i18n";
import PaymentForm from "@/components/Form/Payment/PaymentForm";
import PaymentBranchForm from "@/components/Form/Payment/PaymentBranchForm";
import CardForm from "@/components/Form/Payment/CardForm";

export default {
  name: "GoldFlowManage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    PageLoading,
    PaymentForm,
    PaymentBranchForm,
    CardForm,
  },
  setup() {
    const PageLoading = ref(true);
    const factoriesUrl = `Admin/v1/payment/factory`;
    const branchesUrl = `Admin/v1/payment/branch`;
    const FactoriesForm = ref({});
    const BranchesForm = reactive({});
    const EcpayFront = ref("54b02865-96d3-4ef0-961f-62013f604e7f");
    const EcpayBackend = ref("53a19765-acc3-408b-8660-31ecd35cf591");
    const ForexAccount = ref("cbfc7cd4-4da0-11ec-81d3-0242ac130003");
    const DomestiAccount = ref("3cae2cb4-4dc1-11ec-81d3-0242ac130003");
    const i18n = useI18n();
    // 取得金流商資訊
    const getFactories = async () => {
      return await ApiService.get(factoriesUrl).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;
          FactoriesForm.value = responseData;
        }
      });
    };
    // 取得支付方式列表
    const getBranches = async () => {
      return await ApiService.get(branchesUrl).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;

          for (let i = responseData.length; i--; ) {
            const Form = { staging: {} };
            Form.uuid = responseData[i].uuid;
            Form.title = i18n.t(`ECPAY.BRANCH.BRANCH_${responseData[i].id}`);
            Form.status = responseData[i].status;

            if ("payment_sub_branch" in responseData[i]) {
              const subBranch = responseData[i].payment_sub_branch;
              for (let j = subBranch.length; j--; ) {
                const staging = {};
                staging.parent_uuid = responseData[i].uuid;
                staging.uuid = subBranch[j].uuid;
                staging.title = i18n.t(`ECPAY.STAGING.STAGING_${subBranch[j].id}`);
                staging.status = subBranch[j].status;

                Form.staging[j] = staging;
              }
            }

            BranchesForm[i] = Form;
          }
        }
      });
    };

    return {
      PageLoading,
      getFactories,
      FactoriesForm,
      getBranches,
      BranchesForm,
      EcpayFront,
      EcpayBackend,
      ForexAccount,
      DomestiAccount
    };
  },
  mounted() {
    Promise.all([this.getFactories(), this.getBranches()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
