<template>
  <Form @submit="onSubmit" :initial-values="Form">
    <MDBCardText>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.COUNTRY") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="country" :label="$t('COMMON.COUNTRY')" :rules="countryRules" />
          <ErrorMessage name="country" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKNAME") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="bank_name" :label="$t('COMMON.BANKNAME')" :rules="bank_nameRules" />
          <ErrorMessage name="bank_name" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKBRANCH") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="bank_branch" :label="$t('COMMON.BANKBRANCH')" :rules="bank_branchRules" />
          <ErrorMessage name="bank_branch" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKADDRESS") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="bank_address" :label="$t('COMMON.BANKADDRESS')" :rules="bank_addressRules" />
          <ErrorMessage name="bank_address" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.SWIFTCODE") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="swift_code" :label="$t('COMMON.SWIFTCODE')" :rules="swift_codeRules" />
          <ErrorMessage name="swift_code" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKACCOUNT") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="account" :label="$t('COMMON.BANKACCOUNT')" :rules="accountRules" />
          <ErrorMessage name="account" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKACCOUNTNAME") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="account_name" :label="$t('COMMON.BANKACCOUNTNAME')" :rules="account_nameRules" />
          <ErrorMessage name="account_name" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKACCOUNTPHONE") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="phone" :label="$t('COMMON.BANKACCOUNTPHONE')" :rules="phoneRules" />
          <ErrorMessage name="phone" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("COMMON.BANKACCOUNTADDRESS") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="address" :label="$t('COMMON.BANKACCOUNTADDRESS')" :rules="addressRules" />
          <ErrorMessage name="address" class="text-danger" />
        </MDBCol>
      </MDBRow>
    </MDBCardText>
    <MDBRow class="mb-3">
      <MDBCol>
        <MDBBtn color="primary" type="submit">
          {{ $t("COMMON.SAVE") }}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </Form>
</template>

<script>
import { MDBRow, MDBCol, MDBBtn, MDBCardText } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { useI18n } from "vue-i18n";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CardForm",
  components: {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBCardText,
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const factoriesUrl = `Admin/v1/payment/factory`;
    const i18n = useI18n();

    const countryRules = yup.string().required().label(i18n.t("COMMON.COUNTRY"));
    const bank_nameRules = yup.string().required().label(i18n.t("COMMON.BANKNAME"));
    const bank_branchRules = yup.string().required().label(i18n.t("COMMON.BANKBRANCH"));
    const bank_addressRules = yup.string().required().label(i18n.t("COMMON.BANKADDRESS"));
    const swift_codeRules = yup.string().required().label(i18n.t("COMMON.SWIFTCODE"));
    const accountRules = yup.string().required().label(i18n.t("COMMON.BANKACCOUNT"));
    const account_nameRules = yup.string().required().label(i18n.t("COMMON.BANKACCOUNTNAME"));
    const phoneRules = yup.string().required().label(i18n.t("COMMON.BANKACCOUNTPHONE"));
    const addressRules = yup.string().required().label(i18n.t("COMMON.BANKACCOUNTADDRESS"));

    const Form = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });

    const uuid = computed({
      get: () => props.modelValue,
      set: (val) => emit("update:modelValue", val),
    });

    const onSubmit = async (values) => {
      return await ApiService.update(factoriesUrl, uuid.value, values).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
        }
      });
    };

    return {
      Form,
      onSubmit,
      countryRules,
      bank_nameRules,
      bank_addressRules,
      swift_codeRules,
      accountRules,
      account_nameRules,
      phoneRules,
      addressRules,
      bank_branchRules,
    };
  },
};
</script>
