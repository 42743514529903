<template>
  <Form @submit="onSubmit" :initial-values="Form">
    <MDBCardText>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("ECPAY.STORENUMBER") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="id" :label="$t('ECPAY.STORENUMBER')" :rules="idRules" />
          <ErrorMessage name="id" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("ECPAY.HASHKEY") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="key" :label="$t('ECPAY.HASHKEY')" :rules="keyRules" />
          <ErrorMessage name="key" class="text-danger" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mb-3">
        <MDBCol md="4">
          <label>{{ $t("ECPAY.HASHIV") }}</label>
        </MDBCol>
        <MDBCol md="8">
          <Field name="iv" :label="$t('ECPAY.HASHIV')" :rules="ivRules" />
          <ErrorMessage name="iv" class="text-danger" />
        </MDBCol>
      </MDBRow>
    </MDBCardText>
    <MDBRow class="mb-3">
      <MDBCol>
        <MDBBtn color="primary" type="submit">
          {{ $t("COMMON.SAVE") }}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </Form>
</template>

<script>
import { MDBRow, MDBCol, MDBBtn, MDBCardText } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { useI18n } from "vue-i18n";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "PaymentForm",
  components: {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBCardText,
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const factoriesUrl = `Admin/v1/payment/factory`;
    const i18n = useI18n();

    const idRules = yup.string().required().label(i18n.t("ECPAY.STORENUMBER"));
    const keyRules = yup.string().required().label(i18n.t("ECPAY.HASHKEY"));
    const ivRules = yup.string().required().label(i18n.t("ECPAY.HASHIV"));

    const Form = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });

    const uuid = computed({
      get: () => props.modelValue,
      set: (val) => emit("update:modelValue", val),
    });
    const onSubmit = async (values) => {
      return await ApiService.update(factoriesUrl, uuid.value, values).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
        }
      });
    };

    return {
      Form,
      onSubmit,
      idRules,
      keyRules,
      ivRules,
    };
  },
};
</script>
